import { HttpEventType, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "app/core/services/api.service";
import { AppLoaderService } from "app/core/services/app-loader.service";
import {
  checkNull,
  FormattedDate,
} from "app/core/services/date-and-time-validators.service";
import { ToastService } from "app/core/services/toast.service";
import { Observable } from "rxjs";
import { ITASK } from "./task-manager-constants";
// import { ITASKDTO } from './task-manager-constants';

@Injectable({
  providedIn: "root",
})
export class TaskManagerService {
  constructor(
    private apiservice: ApiService,
    private toaster: ToastService,
    private loader: AppLoaderService
  ) { }

  uploadFile(file) {
    return this.apiservice.post(`upload/file`, file);
  }

  // Custom Task Controls
  createCustomTask(formData: any): Observable<any> {
    return this.apiservice.post(`task/create`, formData);
  }

  updateCustomtask(): Observable<any> {
    return this.apiservice.post(`task/create`, FormData);
  }

  viewCustomTask(id: any): Observable<any> {
    return this.apiservice.get(`task/view/${id}`);
  }

  /***********************Task Manager Controls***********************/

  getOperationlist(taskDTO: ITASK): Observable<any> {
    let params = new HttpParams();
    for (let data in taskDTO) {
      if (checkNull(taskDTO[data])) {
        params = params.append(data, taskDTO[data]);
      }
    }
    return this.apiservice.get(`operationList`, params);
  }

  getProductionList(taskDTO: ITASK): Observable<any> {
    let params = new HttpParams();
    for (let data in taskDTO) {
      if (checkNull(taskDTO[data])) {
        params = params.append(data, taskDTO[data]);
      }
    }
    return this.apiservice.get(`productionList`, params);
  }

  getQualityList(taskDTO: ITASK): Observable<any> {
    let params = new HttpParams();
    for (let data in taskDTO) {
      if (checkNull(taskDTO[data])) {
        params = params.append(data, taskDTO[data]);
      }
    }
    return this.apiservice.get(`qcList`, params);
  }

  getDeliveryList(taskDTO: ITASK): Observable<any> {
    let params = new HttpParams();
    for (let data in taskDTO) {
      if (checkNull(taskDTO[data])) {
        params = params.append(data, taskDTO[data]);
      }
    }
    return this.apiservice.get(`deliveryList`, params);
  }

  changeStatusOftask(formData: any): Observable<any> {
    //order_item_id-->for order //task_manager_id-->for custom task
    //current_task_stage -->2:Production : 3.Qc : 4.Delivery
    return this.apiservice.post(`statusChange`, formData);
  }

  changeProductionToOperation(formData: any): Observable<any> {
    return this.apiservice.post(`productionToOperationDelete`, formData);
  }

  getAllDepartment(): Observable<any> {
    return this.apiservice.get(`department/getcall`);
  }

  getServiceBasedDepartments(id: any): Observable<any> {
    return this.apiservice.get(`serviceBasedDepartmentGetAll/${id}`);
  }

  getEmployeeList(type, dep) {
    return this.apiservice.get(`employeeGetAll/${dep}/${type}`);
  }

  assignTask(formdata: any): Observable<any> {
    return this.apiservice.post(`assign`, formdata);
  }

  // View Page Functionalities

  getOrderItemBasedDetails(order_item_id: any): Observable<any> {
    return this.apiservice.get(`myorderItem/view/${order_item_id}`);
  }

  getOrderHistoryBasedDetails(task_manager_id: any): Observable<any> {
    return this.apiservice.get(`orderItemHistoryView/${task_manager_id}`);
  }

  getTaskViewDetails(task_manager_id: any): Observable<any> {
    return this.apiservice.get(`task/view/${task_manager_id}`);
  }

  getQCDetails(id: any, stageId): Observable<any> {
    return this.apiservice.get(`qcDetails/${id}/${stageId}`);
  }

  getOnlyQCDetails(id: any, stageId): Observable<any> {
    return this.apiservice.get(`onlyQcDetails/${stageId}`);
  }

  getPreviewDetails(id: any, stageId): Observable<any> {
    return this.apiservice.get(`previewDetails/${id}/${stageId}`);
  }

  updateQCstatus(formData: any): Observable<any> {
    return this.apiservice.post(`qcApprovedRejected`, formData);
  }

  updateQCstatusForTask(formData: any): Observable<any> {
    return this.apiservice.post(`onlyTaskQcApprovedRejected`, formData);
  }

  updateOnlyQC(formdata: any): Observable<any> {
    return this.apiservice.post(`onlyQcApprovedRejected`, formdata);
  }

  updateNoOnlyQC(formdata: any): Observable<any> {
    return this.apiservice.post(`moveToCompletedApprovedRejected`, formdata);
  }

  // Dispatch Call

  dispatchItem = (id: any): Observable<any> => {
    let params = new HttpParams();
    params = params.append(`id`, id);
    return this.apiservice.get(`deliveryToDispatch`, params);
  };

  revokeTask(id: any): Observable<any> {
    let formdata = new FormData();
    formdata.append(`task_manager_id`, id);
    return this.apiservice.post(`custom/revoke`, formdata);
  }

  revokeOrder(id: any, service_id: any): Observable<any> {
    let formdata = new FormData();
    formdata.append(`task_manager_id`, id);
    formdata.append(`service_id`, service_id);
    return this.apiservice.post(`revoke`, formdata);
  }
  // Download Call

  singleFile(file: any, type?: any) {
    let params = new HttpParams();
    params = params.append(`attachment`, file);
    if (type == 1) {
      params = params.append(`module`, "order");
    } else if (type == 22) {
      params = params.append(`module`, "chat");
    } else {
      params = params.append(`module`, "customQc");
    }
    this.loader.open();
    this.apiservice.FilterDownloadFile("downloadSinglefile", params).subscribe(
      (data: any) => {
        switch (data.type) {
          case HttpEventType.DownloadProgress:
            this.loader.close();
            break;
          case HttpEventType.Response:
            this.loader.close();
            const downloadedFile = new Blob([data.body], {
              type: data.body.type,
            });
            const a = document.createElement("a");
            a.setAttribute("style", "display:none;");
            document.body.appendChild(a);
            a.download = `${file}`;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = "_blank";
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      (error) => {
        this.loader.close();
        this.toaster.showErrorToast("Something went wrong");
      }
    );
  }

  zipFile(file: any) {
    let params = new HttpParams();
    params = params.append(`folder`, file);
    this.loader.open();
    this.apiservice.FilterDownloadFile("downloadZip", params).subscribe(
      (data: any) => {
        switch (data.type) {
          case HttpEventType.DownloadProgress:
            this.loader.close();
            break;
          case HttpEventType.Response:
            this.loader.close();
            const downloadedFile = new Blob([data.body], {
              type: data.body.type,
            });
            const a = document.createElement("a");
            a.setAttribute("style", "display:none;");
            document.body.appendChild(a);
            a.download = `Zipfile|${FormattedDate(new Date())}`;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = "_blank";
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      (error) => {
        this.loader.close();
        this.toaster.showErrorToast("Something went wrong");
      }
    );
  }

  getTaskManagerCount(): Observable<any> {
    return this.apiservice.get(`countSummaryTaskManager`);
  }

  getConversationList(id: any): Observable<any> {
    return this.apiservice.get(`admin/chat/view?order_item_id=${id}`);
  }



  assignQC(data) {
    return this.apiservice.post(`qcassign`, data)
  }
  revokeQC(data) {
    return this.apiservice.post(`qcRevoke`, data)
  }

  getTaskPreview(order_items_id) {
    return this.apiservice.get(`previewStageBasedDetails/${order_items_id}`)
  }

  getStageBasedCount(order_items_id) {
    return this.apiservice.get(`previewStageBasedCountDetails/${order_items_id}`)
  }
}
