export const environment = {
  production: false,
  EmpapiURL: 'https://testapi.theprintapp.com/api/V1/EP/', //Development 
  apiURL: 'https://testapi.theprintapp.com/api/V1/AP/', //Development 
  socketUrl: 'https://testchat.theprintapp.com/', //Development 

  // EmpapiURL: 'https://api.theprintapp.com/api/V1/EP/', //staging
  // apiURL: 'https://api.theprintapp.com/api/V1/AP/', //staging
  // socketUrl: 'https://chat.theprintapp.com/', //staging

  firebaseConfig: {
    apiKey: "AIzaSyA3waq5-59JJ_1mbfvA5MZCAuq2MDRCE6M",
    authDomain: "print-app-f9e62.firebaseapp.com",
    projectId: "print-app-f9e62",
    storageBucket: "print-app-f9e62.appspot.com",
    messagingSenderId: "948325265541",
    appId: "1:948325265541:web:6840db866f55f7058839ec",
    measurementId: "G-129C70PLHJ"
  },
};
