<div class="text-center  mat-bg-primary pt-1 pb-1">

    <h2 class="m-0 notify_title">Notifications</h2>

  

    <!-- <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <span>Notification_1</span>
      </button>
      <button mat-menu-item>
        <span>Notification_2</span>
      </button>
    </mat-menu> -->


</div>
<mat-nav-list class="notification-list" role="list">
  <!-- Notification item -->
  <mat-list-item *ngFor="let n of notificationList | slice:0:15; let i = index" (click)="readNotiification(n)"
    class="notific-item" role="listitem" routerLinkActive="open">
    <!-- <mat-icon class="notific-icon mr-1">notification_important</mat-icon> -->
<div class="dynamic_img">
  <img style="width: 28px; margin-right: 10px;" [src]="n.image" alt="">

</div>
    <a>
      <div class="mat-list-text">
        <small class="time text-muted">{{ n.created_on ? (n.created_on | timeago:live) : ''}}</small>
        <h4 matTooltip="{{n.title}}"
        aria-label="text that displays a tooltip when focused or hovered over" class="message">{{n.title}}</h4>

      </div>
    </a>
  </mat-list-item>
</mat-nav-list>
<!-- <div class="text-center mt-1" *ngIf="notifications?.length">
                  <small><a href="#" (click)="clearAll($event)">Clear all notifications</a></small>
                </div> -->

<div class="text-center sticky_bottomView pt-1 pb-1" *ngIf=' notificationList?.length != 0'>
  <small><a (click)="viewMore()" style="color:blue;font-size: 14px; font-weight: 500;">View More</a></small>
</div>
<div class="text-center sticky_bottomView pt-1 pb-1" *ngIf=' notificationList?.length == 0'>
  <small><a (click)="viewMore()" style="color:blue;font-size: 14px; font-weight: 500;">View List</a></small>
</div>