<div class="">
  <div class="float-button new_btn_9">
    <button (click)=" toggleChat()" mat-button class="new-ani-chat-butttom" [matMenuTriggerFor]="menu">
      <mat-icon class="cht-ico oc"> chat</mat-icon>

      <span class="ani-text">View Chat</span>
    </button>
  </div>
  <!-- [matMenuTriggerFor]="menu" -->

  <mat-menu #menu="matMenu" yPosition="above" backdropClass="chat">
    <mat-card class="p-0 m-0 chat-option">
      <mat-card-header>
        <mat-card-title>
          <div (click)="$event.stopPropagation()">
            <img [src]="chatDetails?.profile_image_url ? chatDetails?.profile_image_url : 'assets/images/avartar.jpg'" alt="profile" class="avatar-image" />
            <span class="product-id">{{chatDetails?.customer_first_name}}{{chatDetails?.order_code ? '-':''}}{{chatDetails?.order_code}}</span>
          </div>
          <span class="text-right">
            <mat-icon>close</mat-icon>
          </span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="scroll pt-16" id="chat_conversation" infinite-scroll [scrollWindow]="false"
          [infiniteScrollDistance]="2" (scrolledUp)="onScrollUp($event)" [alwaysCallback]="true"
          (click)="$event.stopPropagation()">

          <div class="no_conversation" *ngIf="conversation?.length==0">


            <img src="assets/images/no_conver.png" alt="">

            <p><b>No Conversation Yet..</b></p>



          </div>

          <div class="" *ngFor="let data of conversation;let i =index">
            <div class="d-flex align-items-center justify-content-center mb-20" *ngIf="checkByIndex(i)">
              <h4 class="mb-0 chat_date">{{data?.created_at|date:'MMM d, y'}}</h4>
            </div>
            <!-- Employee  Message -->
            <div class="employee_chat" *ngIf="(data?.type=='text'||data?.type=='msg')&&data?.fromUserId==chatUserId">
              <label class="message1">
                {{data?.message}}
              </label>
              <p class="personemp">{{data?.created_at|date:'hh:mm a'}}</p>
            </div>

            <!-- Customer  Message -->
            <div class="mt-12" *ngIf="(data?.type=='text'||data?.type=='msg')&&data?.fromUserId!=chatUserId">
              <div class="customer_chat">
                <label class="message2">{{data?.message}}</label>
                <p class="personcus">{{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>
            <!-- Customer Audio -->
            <div class="mt-12" *ngIf="data?.type=='audio'&&data?.fromUserId!=chatUserId">
              <div class="customer_chat">
                <div class="message2">
                  <app-audio-player [type]="2" [audioSrc]="data?.file"></app-audio-player>
                </div>
                <p class="personcus">{{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>
            <!-- Employee Audio -->
            <div class="mt-12" *ngIf="data?.type=='audio'&&data?.fromUserId==chatUserId">
              <div class="employee_chat">

                <div class="message1">
                  <app-audio-player [type]="2" [audioSrc]="data?.file"></app-audio-player>
                </div>

                <p class="personemp">{{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>

            <!-- Customer Video -->
            <div class="mt-12" *ngIf="data?.type=='video'&&data?.fromUserId!=chatUserId">
              <div class="customer_chat">
                <div class="message2">
                  <div class="video">
                    <video width="160" height="100" controls>
                      <source [src]="data?.file" type="video/mp4">
                    </video>
                  </div>
                </div>
                <p class="personcus">{{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>

            <!-- Employee Video -->
            <div class="mt-12" *ngIf="data?.type=='video'&&data?.fromUserId==chatUserId">
              <div class="employee_chat">
                <div class="message1">
                  <div class="video">
                    <video width="160" height="100" controls>
                      <source [src]="data?.file" type="video/mp4">
                    </video>
                  </div>
                </div>
                <p class="personemp">{{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>

            <!-- Customer Image -->
            <div class="mt-12" *ngIf="data?.type=='image'&&data?.fromUserId!=chatUserId">
              <div class="customer_chat">
                <div class="message2"  style="position: relative;">
                  <div class="img">
                    <div class="download-option" ><i class="fas fa-arrow-alt-circle-down"></i></div>
                    <a> <img height="160" width="160" [src]="data?.file" alt=""></a>
                    <!-- <a class="download-option" (click)="downloadFile(data)"><i class="fas fa-arrow-alt-circle-down"></i></a> -->
                  </div>
                </div>
                <p class="personcus">{{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>

            <!-- Employee Image -->
            <div class="mt-12" *ngIf="data?.type=='image'&&data?.fromUserId==chatUserId">
              <div class="employee_chat">
                <div class="message1"  style="position: relative;">
                  <div class="img">
                    <div class="download-option" ><i class="fas fa-arrow-alt-circle-down"></i></div>
                    <a target="_blank" [href]="data?.file"><img height="160" width="160" [src]="data?.file" alt=""></a>
                    <a class="download-option" (click)="downloadFile(data)"><i class="fas fa-arrow-alt-circle-down"></i></a>
                  </div>
                </div>
                <p class="personemp">{{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>

            <!-- Customer File -->
            <div class="mt-12" *ngIf="data?.type=='pdf'&&data?.fromUserId!=chatUserId">
              <div class="customer_chat">
                <div class="message2">
                  <div class="audio">
                    <span><i class="fas fa-file"></i></span>
                    <label class="mb-0 ml-8 mr-8">{{data.message}}</label>
                    <i class="fas fa-arrow-alt-circle-down" (click)="downloadFile(data)"></i>
                  </div>
                </div>
                <p class="personcus">{{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>

            <!-- Employee File -->
            <div class="mt-12" *ngIf="data?.type=='pdf'&&data?.fromUserId==chatUserId">
              <div class="employee_chat">
                <div class="message1">
                  <div class="audio">
                    <span><i class="fas fa-file"></i></span>
                    <label class="mb-0 ml-8 mr-8 text-trim">{{data.message}}</label>
                    <i class="fas fa-arrow-alt-circle-down" (click)="downloadFile(data)"></i>
                  </div>
                </div>
                <p class="personemp">{{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>
          </div>
        </div>






      </mat-card-content>
    </mat-card>
  </mat-menu>
</div>

<audio controls hidden id="alertMsg">
  <source src="/src/assets/audio/window_8.mp3">
</audio>