import { get } from "lodash";
import { constant } from '../helpers/global.helper';
import { decodedToken } from '../helpers/token.helper';
import { FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";


var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];


var daysShort = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
];

const monthFullNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const monthNames = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
];

export function getDateFnsFormat() {
    var configDF = appSetting().dateFormat;
    var dateFnsFormat = '';
    if (configDF == 'DD-MM-YYYY') {
        dateFnsFormat = 'DD-MM-YYYY';
    }

    if (configDF == 'DD/MM/YYYY') {
        dateFnsFormat = 'DD/MM/YYYY';
    }

    if (configDF == 'YYYY-MM-DD') {
        dateFnsFormat = 'YYYY-MM-DD';
    }

    if (configDF == 'YYYY/MM/DD') {
        dateFnsFormat = 'YYYY/MM/DD';
    }

    if (configDF == 'MM-DD-YYYY') {
        dateFnsFormat = 'MM-DD-YYYY';
    }

    if (configDF == 'MM/DD/YYYY') {
        dateFnsFormat = 'MM/DD/YYYY';
    }

    return dateFnsFormat;
}


export function getUniqDateFnsFormat() {
    var configDF = appSetting().dateFormat;
    var dateFnsFormat = '';
    if (configDF == 'DD-MM-YYYY') {
        dateFnsFormat = 'DD-MMM-YYYY';
    }

    if (configDF == 'DD/MM/YYYY') {
        dateFnsFormat = 'DD-MMM-YYYY';
    }

    if (configDF == 'YYYY-MM-DD') {
        dateFnsFormat = 'YYYY-MMM-DD';
    }

    if (configDF == 'YYYY/MM/DD') {
        dateFnsFormat = 'YYYY-MMM-DD';
    }

    if (configDF == 'MM-DD-YYYY') {
        dateFnsFormat = 'MMM-DD-YYYY';
    }

    if (configDF == 'MM/DD/YYYY') {
        dateFnsFormat = 'MMM-DD-YYYY';
    }

    return dateFnsFormat;
}



export function getDateTimeFnsFormat() {
    return `${getDateFnsFormat()} ${getTimeFnsFormat()}`;
}

export function getFullDayName(d: Date) {
    return days[d.getDay()] ?? "";
}

export function getTimeFnsFormat() {
    return appSetting().timeFormat == '12' ? 'hh:mm:ss a' : 'HH:mm:ss';
}

export function appSetting() {
    const token = decodedToken();
    var dateSep = storedConfig().dateSep ? storedConfig().dateSep : get(token, 'date_separator') ? get(token, 'date_separator') : '';
    var dateRawFor = storedConfig().dateRawFormat ? storedConfig().dateRawFormat : get(token, 'date_format') ? get(token, 'date_format') : '';
    var timeFor = storedConfig().timeFormat ? storedConfig().timeFormat : get(token, 'time_format') ? get(token, 'time_format') : '';
    var dateFor = storedConfig().dateRawFormat ? storedConfig().dateRawFormat : dateRawFor ? dateRawFor : '';
    dateFor = dateRawFor.replace(/\s/g, dateSep);
    return {
        dateSeparator: dateSep,
        dateRawFormat: dateRawFor,
        timeFormat: timeFor,
        dateFormat: dateFor
    }
}



function storedConfig() {
    return {
        dateFormat: window.localStorage.getItem(constant().app.config.dateFormat),
        dateRawFormat: window.localStorage.getItem(constant().app.config.dateRawFormat),
        dateSep: window.localStorage.getItem(constant().app.config.dateSep),
        timeFormat: window.localStorage.getItem(constant().app.config.timeFormat),
    };
}
export function fromToWeightValidator(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
        const weightFrom = formGroup.get('weight_from').value;
        const weightTo = formGroup.get('weight_to').value;

        if (weightTo === null || weightTo === undefined || weightTo === '') {
            formGroup.get('weight_to').setErrors({ required: true });
        } else if (Number(weightTo) < Number(weightFrom)) {
            formGroup.get('weight_to').setErrors({ fromToWeightError: true });
        } else if (weightTo && !(/^\d*\.?\d{1}$/.test(weightTo))) {
            formGroup.get('weight_to').setErrors({ pattern: true });
        } else {
            formGroup.get('weight_to').setErrors(null);
        }

        return null;
    };
}



export function convertNumberToWords(amount) {
    var words = [
        '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
        'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen',
        'Seventeen', 'Eighteen', 'Nineteen', 'Twenty', 'Thirty', 'Forty', 'Fifty',
        'Sixty', 'Seventy', 'Eighty', 'Ninety'
    ];

    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";

    if (n_length <= 9) {
        var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
        var received_n_array = new Array();

        for (var i = 0; i < n_length; i++) {
            received_n_array[i] = number.substr(i, 1);
        }

        for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
            n_array[i] = received_n_array[j];
        }

        let value;

        for (var i = 0; i < 9; i++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                value = n_array[i] * 10;
            } else {
                value = n_array[i];
            }

            if (value != 0) {
                if (i === 6 && value >= 100) {
                    words_string += 'Hundred ';
                } else if (i < 6 && value >= 1000) {
                    words_string += '1k ';
                } else {
                    words_string += words[value] + " ";
                }
            }
        }

        words_string = words_string.trim().split("  ").join(" ");
    }

    return words_string;
}