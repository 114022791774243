import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ChatSocketService } from "app/core/services/chat-socket.service";
import { checkNull, FormattedDate } from "app/core/services/date-and-time-validators.service";
import { EmployeeService } from "app/core/services/employee.service";
import { SocketIoService } from "app/core/services/socket-io.service";
import { ToastService } from "app/core/services/toast.service";
import { VoiceRecorderService } from "app/core/services/voice-recorder.service";
import { TaskManagerService } from "app/modules/admin/task-manager/service/task-manager.service";
import { ChatDTO } from "./chat-constants";
declare var $: any;

interface IMSG {
  fromUserId?: any;
  toUserId?: any;
  message?: any;
  order_items_id?: any;
  status?: any;
  created_at?: any;
  id?: any;
  file?: any;
  type?: any;
  isFile?: any;
  user_type?: any;
  name?: any;
  date?: any;
}

const audioFormats: any[] = [
  `audio/mpeg`,
  "audio/wav",
  "audio/mp3",
  "audio/m4a",
];
const videoFormats: any[] = [`video/mp4`, `video/mkv`, `video/x-matroska`];
const imageFormats: any[] = [`image/jpeg`, `image/png`, "image/jpg"];
const documentFormats: any[] = [
  `application/pdf`,
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];

function checkFile(type) {
  let audio = audioFormats.includes(type);
  let image = imageFormats.includes(type);
  let video = videoFormats.includes(type);
  let pdf = documentFormats.includes(type);

  return audio
    ? "audio"
    : video
      ? "video"
      : image
        ? "image"
        : pdf
          ? "pdf"
          : "text";
}

@Component({
  selector: "app-chat-view-admin",
  templateUrl: "./chat-view-admin.component.html",
  styleUrls: ["./chat-view-admin.component.css"],
})
export class ChatViewAdminComponent
  implements OnInit, OnChanges, AfterViewInit {
  toggleBool: boolean = false;

  chatDetails: any;
  @Input("conversationList") public conversationList: any[];
  @Input("order") public order: any;
  conversation: any[] = [];
  constructor(
    private audioRecordingService: VoiceRecorderService,
    private sanitizer: DomSanitizer,
    private formbuilder: FormBuilder,
    private toaster: ToastService,
    private taskManagerService: TaskManagerService
  ) { }

  async ngOnChanges(changes) {
    await this.setMessages();
  }

  toggleChat() {
    this.toggleBool = this.toggleBool ? !this.toggleBool : true;
  }

  ngAfterViewInit(): void { }

  ngOnInit(): void { }

  setMessages() {
    this.conversation = this.conversationList.map((ele: any) => {
      let obj: IMSG = {};
      obj.fromUserId = ele?.fromUserId ?? "";
      obj.toUserId = ele?.toUserId ?? "";
      obj.message = ele?.message ?? "";
      obj.created_at = ele?.created_at ?? "";
      obj.id = ele?.id;
      obj.file = ele?.path + ele?.message;
      obj.type = checkFile(ele.type);
      obj.isFile = checkNull(ele?.file);
      obj.user_type = ele?.user_type;
      obj.name = ele?.customer_name;
      obj.date = ele?.created_at
      return obj;
    });
    console.log(this.conversation, 'gtm');

    setTimeout(() => {
      $("#chat_conversation").animate(
        {
          scrollTop: $("#chat_conversation")[0]?.scrollHeight,
        },
        500
      );
    }, 500);
  }

  checkDate(crnt, bfr) {

  }

  checkByIndex(index: any) {
    if (index == 0) {
      return true
    } else {
      return (FormattedDate(this.conversation[index]?.date))!=(FormattedDate(this.conversation[index - 1]?.date))
    }
  }


  downloadFile(data: any) {
    console.log(data);
    this.taskManagerService.singleFile(data?.message, 22);
  }

}
